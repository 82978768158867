import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'qs'
import _ from 'lodash'
import moment from 'moment'

// Components
import GridContainer from 'components/GridContainer'
import { callAPI } from 'helpers'
import Spinner from 'components/Spinner'
import Heading from 'components/Heading'
import ContentWrapper from 'components/ContentWrapper'
import { Button, Empty, Modal, Input, DatePicker, Table, Icon, message } from 'antd'
import FormGroup from 'components/FormGroup'
import FormSelect from 'components/FormSelect'
import Label from 'components/Label'
import CustomPopconfirm from 'components/CustomPopconfirm'

// Hooks
import { useEditable } from 'hooks/useEditable'

const LIMIT = 20

const LiveFeedRounds = () => {
  const editable = useEditable()
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [roundCount, setRoundCount] = useState(0)
  const [rounds, setRounds] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [liveFeedClasses, setLiveFeedClasses] = useState([])
  const [onlineClasses, setOnlineClasses] = useState([])

  const currentPage = parseInt(_.get(qs.parse(location.search.slice(1)), 'page') || 1, 10)
  const offset = (currentPage - 1) * LIMIT

  useEffect(() => {
    const initialFetch = async () => {
      try {
        setLoading(true)
        const [{ result: _classes },  { result: _roundCount }, { result: _rounds }, { result: _onlineClasses }] = await Promise.all([
          callAPI({ url: '/offline-classes' }),
          callAPI({ url: '/live-feed-rounds/count' }),
          callAPI({ url: '/live-feed-rounds', query: { offset, limit: LIMIT } }),
          callAPI({ url: '/online-classes', query: { limit: 10000 } })
        ])
        setLoading(false)
        setLiveFeedClasses(_classes)
        setRoundCount(_roundCount)
        setRounds(_rounds)
        setOnlineClasses(_onlineClasses)
        setHasMore()
      } catch (error) {
        console.error('Error while trying to initial fetch.', error)
        setLoading(false)
        setRounds([])
      }
    }

    initialFetch()
  }, [])

  const [modalVisible, setModalVisible] = useState(false)
  const [editedRoundId, setEditedRoundId] = useState()
  const [classCode, setClassCode] = useState()
  const [classDateStopMoment, setClassDateStopMoment] = useState()
  const [seatCount, setSeatCount] = useState()
  const [onlineClassId, setOnlineClassId] = useState('')
  const [compensationMonths, setCompensationMonths] = useState(6);
  // const [additionalHour, setAdditionalHour] = useState()

  useEffect(() => {
    if (!modalVisible) {
      setEditedRoundId()
      setClassCode()
      setClassDateStopMoment()
      setSeatCount()
      setCompensationMonths(6)
      // setAdditionalHour()
    }
  }, [modalVisible])

  const [creating, setCreating] = useState(false)
  const _onRoundCreate = async () => {
    if (!editable || creating) return
    try {
      setCreating(true)
      const { result: _createdRound } = await callAPI({
        method: 'POST',
        url: '/live-feed-rounds',
        body: {
          classCode,
          semesterCode: _.get(_.find(liveFeedClasses, _class => _class.classCode === classCode), 'semesterCode'),
          className: _.get(_.find(liveFeedClasses, _class => _class.classCode === classCode), 'className'),
          classTypeName: _.get(_.find(liveFeedClasses, _class => _class.classCode === classCode), 'classTypeName') || 'Live',
          classDateStop: classDateStopMoment.format('YYYY-MM-DD'),
          seatCount
        }
      })
      message.success('เพิ่มรอบสมัครสำเร็จ')
      setCreating(false)
      setModalVisible(false)
      setRounds(_rounds => [_createdRound].concat(_rounds))
      setRoundCount(_count => _count + 1)
    } catch (error) {
      console.error('Error while trying to create a round.', error)
      message.error(`เพิ่มรอบสมัครไม่สำเร็จ, ${error.reason}`)
      setCreating(false)
    }
  }

  const [saving, setSaving] = useState(false)
  const _onRoundSave = async () => {
    if (!editable || saving) return
    try {
      setSaving(true)
      const { result: _savedRound } = await callAPI({
        method: 'PUT',
        url: `/live-feed-rounds/${editedRoundId}`,
        body: {
          classCode,
          semesterCode: _.get(_.find(liveFeedClasses, _class => _class.classCode === classCode), 'semesterCode'),
          classDateStop: classDateStopMoment.format('YYYY-MM-DD'),
          seatCount,
          onlineClassId,
          compensationMonths
          // additionalHour
        }
      })
      message.success('บันทึกรอบสมัครสำเร็จ')
      setSaving(false)
      setModalVisible(false)
      setRounds(_rounds => _rounds.map(_round =>
        _round.roundId === _savedRound.roundId
          ? _savedRound
          : _round
      ))
    } catch (error) {
      console.error('Error while trying to save a round.', error)
      message.error(`บันทึกรอบสมัครไม่สำเร็จ, ${error.reason}`)
      setSaving(false)
    }
  }

  const [removing, setRemoving] = useState(false)
  const _onRoundRemove = async (_roundId) => {
    if (!editable || removing) return
    try {
      setRemoving(true)
      await callAPI({
        method: 'DELETE',
        url: `/live-feed-rounds/${_roundId}`
      })
      setRemoving(false)
      setRounds(_rounds => _rounds.filter(_round => _round.roundId !== _roundId))
      setRoundCount(_count => _count - 1)
      message.success('ลบรอบสมัครสำเร็จ')
    } catch (error) {
      console.error('Error while trying to remove a round.', error)
      setRemoving(false)
      message.error('ลบรอบสมัครไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const [updating, setUpdating] = useState(false)
  const _onOnlineClassUpdate = async (_roundId) => {
    if (!editable || updating) return
    try {
      setUpdating(true)
      const { result } = await callAPI({
        method: 'POST',
        url: `/live-feed-rounds/${_roundId}/online-classes`
      })
      setUpdating(false)
      message.success(`เพิ่มชดเชยสำเร็จ จำนวนทั้งสิ้น ${result.count} คน`)
    } catch (error) {
      console.error('Error while trying to update online class', error)
      setUpdating(false)
      message.error('เพิ่มชดเชยไม่สำเร็จ, กรุณาลองใหม่อีกครั้ง')
    }
  }

  const _classOptions = liveFeedClasses.map(({ semesterCode, classType, courseCode, classCode }) => ({
    label: `[${semesterCode}] ${courseCode} (${classType || 'Live'})`,
    value: classCode
  }))

  const _columns = _.compact([{
    key: 'createdAt',
    title: 'สร้างเมื่อ',
    width: 150,
    align: 'center',
    render: ({ createdAt }) => createdAt
      ? moment(createdAt).format('DD/MM/YYYY')
      : null
  }, {
    key: 'classCode',
    title: 'รหัสวิชา',
    width: 100,
    align: 'center',
    render: ({ classCode }) => classCode
  }, {
    key: 'semesterCode',
    title: 'ภาคเรียนที่',
    width: 100,
    align: 'center',
    render: ({ semesterCode }) => semesterCode
  }, {
    key: 'classTypeName',
    title: 'ประเภทรอบ',
    width: 100,
    align: 'center',
    render: ({ classTypeName }) =>
      classTypeName === 'Live'
        ? 'รอบสด'
        : classTypeName === 'Feed' ? 'ถ่ายทอดสด' : '-'
  }, {
    key: 'className',
    title: 'ชื่อวิชา',
    width: 300,
    align: 'left',
    render: ({ className }) => className
  }, {
    key: 'seatCount',
    title: 'จำนวนที่นั่ง',
    width: 100,
    align: 'center',
    render: ({ seatCount }) => seatCount
  }, {
    key: 'classDateStop',
    title: 'เปิดขายจนถึงวันที่',
    width: 150,
    align: 'center',
    render: ({ classDateStop }) => classDateStop
      ? moment(classDateStop, 'YYYY-MM-DD').format('DD/MM/YYYY')
      : '-'
  }, {
    key: 'onlineClassId',
    title: 'คอร์สเรียนออนไลน์ชดเชย',
    width: 200,
    align: 'center',
    render: ({ roundId, onlineClassId }) => {
      const onlineClass = _.find(onlineClasses, ({ classId }) => classId === onlineClassId)
      return (<div>
        {!onlineClass ? '-' : onlineClass.className}
        {editable && Boolean(onlineClass) && (
        <CustomPopconfirm
          disabled={removing}
          title={`ยืนยันที่จะเพิ่มคอร์สเรียนออนไลน์ชดเชย ${onlineClass.classCode} ภาคเรียน ${onlineClass.semesterCode} ?`}
          icon={<Icon type='question-circle-o' />}
          okType='primary'
          onConfirm={() => _onOnlineClassUpdate(roundId)}>
            <Button
              type='primary'
              loading={updating}
              style={{ marginTop: 10 }}
              icon='plus'>
              เพิ่มชดเชย
            </Button>
          </CustomPopconfirm>
        )}
      </div>
      )
    }
  }, editable ? {
    key: 'buttons',
    title: '',
    width: 110,
    render: ({ roundId, classCode, semesterCode }) => (
      <div>
        <Button
          disabled={removing}
          style={{ marginRight: 10 }}
          icon='edit'
          onClick={() => {
            setModalVisible(true)
            setEditedRoundId(roundId)
            const _round = _.find(rounds, _round => _round.roundId === roundId) || {}
            setClassCode(_round.classCode)
            setClassDateStopMoment(_round.classDateStop
              ? moment(_round.classDateStop, 'YYYY-MM-DD')
              : null)
            setSeatCount(_round.seatCount)
            setOnlineClassId(_round.onlineClassId)
            setCompensationMonths(_round.compensationMonths || 6)
            // setAdditionalHour(_round.additionalHour)
          }} />
        <CustomPopconfirm
          disabled={removing}
          title={`ยืนยันที่จะลบรอบสมัครรหัสวิชา ${classCode} ของภาคเรียน ${semesterCode} ?`}
          icon={<Icon type='question-circle-o' />}
          onConfirm={() => _onRoundRemove(roundId)}>
          <Button loading={removing} disabled={removing} type='danger' icon='delete' />
        </CustomPopconfirm>
      </div>
    )
  } : null])

  return (
    <section>
      <GridContainer>
        <Heading
          disabled={!editable}
          title={`รอบสมัครรอบสด/ถ่ายทอดสด ${roundCount ? `(${roundCount})` : ''}`}
          buttonTitle='เพิ่มรอบสมัคร'
          buttonProps={{ onClick: () => setModalVisible(true) }} />
        {loading ? (
          <ContentWrapper><Spinner /></ContentWrapper>
        ) : rounds.length ? (
          <ContentWrapper style={{ padding: 0 }}>
            <Table
              rowKey={({ roundId }) => roundId}
              columns={_columns}
              dataSource={rounds}
              pagination={false} />
          </ContentWrapper>
        ) : (
          <ContentWrapper>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={<span>ยังไม่มีข้อมูลรอบสมัคร</span>}>
              {editable && <Button type='primary' icon='plus' onClick={() => setModalVisible(true)}>เพิ่มรอบสมัคร</Button>}
            </Empty>
          </ContentWrapper>
        )}
      </GridContainer>
      <Modal
        visible={editable && modalVisible}
        title={`${editedRoundId ? 'แก้ไขรอบสมัคร' : 'เพิ่มรอบสมัคร'}`}
        okText={`${editedRoundId ? 'บันทึกข้อมูล' : 'เพิ่มข้อมูล'}`}
        okButtonProps={{
          loading: editedRoundId ? saving : creating,
          disabled: (editedRoundId ? saving : creating) || !classCode || !classDateStopMoment || !seatCount
        }}
        onOk={editedRoundId ? _onRoundSave : _onRoundCreate}
        cancelText='ยกเลิก'
        onCancel={() => setModalVisible(false)}>
        <FormGroup>
          <Label required>รหัสวิชา</Label>
          <FormSelect
            disabled={Boolean(editedRoundId)}
            style={{ width: '100%' }}
            value={classCode}
            onChange={e => {
              const value = e.target.value
              setClassCode(value)
              const _currentClass = _.find(liveFeedClasses, _class => _class.classCode === value)
              const _classDateStop = _.get(_currentClass, 'classDateStop')
              setClassDateStopMoment(_classDateStop ? moment(_classDateStop) : null)
            }}>
            <option></option>
            {_classOptions.map(({ label, value }) => <option key={value} value={value}>{value}: {label}</option>)}
          </FormSelect>
          <div />
        </FormGroup>
        <FormGroup>
          <Label required>เปิดขายจนถึงวันที่</Label>
          <div>
            <DatePicker
              style={{ width: '100%' }}
              placeholder=''
              value={classDateStopMoment}
              onChange={(date, dateString) => setClassDateStopMoment(date)} />
          </div>
        </FormGroup>
        <FormGroup>
          <Label required>จำนวนที่นั่ง</Label>
          <div>
            <Input
              type='text'
              value={seatCount}
              onChange={e => {
                const _count = e.target.value
                if (_count && isNaN(_count)) return
                setSeatCount(_count)
              }} />
          </div>
        </FormGroup>
        {editedRoundId && (
          <div>
            <FormGroup>
              <Label>คอร์สออนไลน์ชดเชย</Label>
              <div>
              <FormSelect
                style={{ width: '100%' }}
                value={onlineClassId}
                onChange={e => setOnlineClassId(e.target.value)}>
                <option key='' value=''></option>
                {onlineClasses
                  .map(({ classId, classCode, semesterCode, courseName }) => <option key={classId} value={classId}>{classCode}: [{semesterCode}] {courseName}</option>)}
              </FormSelect>
              </div>
            </FormGroup>
            <FormGroup>
              <Label>จำนวนเดือนชดเชย</Label>
              <div>
                <Input
                  type='text'
                  value={compensationMonths}
                  onChange={e => {
                    const months = e.target.value;
                    if (months && isNaN(months)) return;
                    setCompensationMonths(months)
                  }} />
              </div>
            </FormGroup>
          </div>
        )}
      </Modal>
    </section>
  )
}

export default LiveFeedRounds
